import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("El nombre es requerido"),
  marca: Yup.string().required("La marca es requerida"),
  modelo: Yup.string().required("El modelo es requerido"),
  patente: Yup.string().required("La patente es requerida"),
  anioFabricacion: Yup.number().required("El año de fabricación es requerido"),
  estado: Yup.string().required("El estado es requerido"),
  kilometros: Yup.number().required("Los kilómetros son requeridos"),
  precio: Yup.number().required("El precio es requerido"),
  puertas: Yup.number().required("La cantidad de puertas es requerida"),
  color: Yup.string().required("El color es requerido"),
  combustible: Yup.string().required("El tipo de combustible es requerido"),
  transmision: Yup.string().required("El tipo de transmisión es requerido"),
  motor: Yup.string().required("La capacidad del motor es requerida"),
  version: Yup.string().required("La versión es requerida"),
  abs: Yup.bool(),
  gnc: Yup.bool(),
});

export const initialValues = {
  marca: "",
  modelo: "",
  patente: "",
  añoFabricacion: "",
  version: "",
  color: "",
  combustible: "",
  puertas: "",
  transmision: "",
  motor: "",
  kilometros: "",
  tanque: "",
  precio: "",
  abs: false,
  gnc: false,
  imagenes: [],
};

export const mockData = [
  {
    id: 1,
    marca: "Fiat",
    modelo: "Toro",
    precio: 28500000,
    añoFabricacion: 2024,
    version: "1.8 Freedom",
    color: "Blanco",
    combustible: "Nafta",
    puertas: 4,
    transmision: "Automática",
    motor: 1.8,
    kilometros: 1000,
    tanque: 60,
    abs: true,
    gnc: false,
    fechaPublicacion: "01/01/2022",
  },
  {
    id: 2,
    marca: "Toyota",
    modelo: "Hilux",
    precio: 35000000,
    añoFabricacion: 2023,
    version: "SRV 2.8 TD",
    color: "Negro",
    combustible: "Gasoil",
    puertas: 4,
    transmision: "Manual",
    motor: 2.8,
    kilometros: 5000,
    tanque: 80,
    abs: true,
    gnc: false,
    fechaPublicacion: "02/01/2022",
  },
  {
    id: 3,
    marca: "Chevrolet",
    modelo: "Onix",
    precio: 20000000,
    añoFabricacion: 2022,
    version: "LTZ 1.0 Turbo",
    color: "Rojo",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Manual",
    motor: 1.0,
    kilometros: 0,
    tanque: 45,
    abs: true,
    gnc: true,
    fechaPublicacion: "03/01/2022",
  },
];


export const mockData2 = [
  {
    id: 1,
    marca: "Fiat",
    modelo: "Toro",
    precio: 28500000,
    añoFabricacion: 2024,
    version: "1.8 Freedom",
    color: "Blanco",
    combustible: "Nafta",
    puertas: 4,
    transmision: "Automática",
    motor: 1.8,
    kilometros: 1000,
    tanque: 60,
    abs: true,
    gnc: false,
    fechaPublicacion: "01/01/2022",
    descripcionCorta: "Camioneta mediana con estilo y potencia.",
    descripcionLarga: "La Fiat Toro es una camioneta mediana con un diseño atractivo y una excelente relación entre potencia y eficiencia. Ofrece un interior amplio y cómodo, además de una gran capacidad de carga. Perfecta para viajes familiares y actividades al aire libre.",
  },
  {
    id: 2,
    marca: "Toyota",
    modelo: "Hilux",
    precio: 35000000,
    añoFabricacion: 2023,
    version: "SRV 2.8 TD",
    color: "Negro",
    combustible: "Gasoil",
    puertas: 4,
    transmision: "Manual",
    motor: 2.8,
    kilometros: 5000,
    tanque: 80,
    abs: true,
    gnc: false,
    fechaPublicacion: "02/01/2022",
    descripcionCorta: "Pickup resistente y confiable para trabajo pesado.",
    descripcionLarga: "La Toyota Hilux es una de las pickups más icónicas y duraderas del mercado. Cuenta con un motor diésel potente, una gran capacidad de carga y una excelente tracción en todo terreno. Ideal para trabajos pesados y aventuras off-road.",
  },
  {
    id: 3,
    marca: "Chevrolet",
    modelo: "Onix",
    precio: 20000000,
    añoFabricacion: 2022,
    version: "LTZ 1.0 Turbo",
    color: "Rojo",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Manual",
    motor: 1.0,
    kilometros: 0,
    tanque: 45,
    abs: true,
    gnc: true,
    fechaPublicacion: "03/01/2022",
    descripcionCorta: "Compacto y ágil para la ciudad.",
    descripcionLarga: "El Chevrolet Onix es un automóvil compacto y ágil, perfecto para moverse por la ciudad. Ofrece un interior espacioso y cómodo, además de un rendimiento de combustible excepcional gracias a su motor turbo de 1.0 litros.",
  },
  {
    id: 4,
    marca: "Volkswagen",
    modelo: "Gol",
    precio: 18000000,
    añoFabricacion: 2023,
    version: "Trendline 1.6",
    color: "Azul",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Manual",
    motor: 1.6,
    kilometros: 20000,
    tanque: 50,
    abs: true,
    gnc: false,
    fechaPublicacion: "04/01/2022",
    descripcionCorta: "Clásico y confiable para la familia.",
    descripcionLarga: "El Volkswagen Gol es un automóvil clásico y confiable, ideal para la vida familiar. Ofrece un amplio espacio interior, un rendimiento sólido y una gran relación calidad-precio.",
  },
  {
    id: 5,
    marca: "Renault",
    modelo: "Kwid",
    precio: 15000000,
    añoFabricacion: 2022,
    version: "Zen 1.0",
    color: "Blanco",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Manual",
    motor: 1.0,
    kilometros: 10000,
    tanque: 38,
    abs: true,
    gnc: false,
    fechaPublicacion: "05/01/2022",
    descripcionCorta: "Económico y práctico para la ciudad.",
    descripcionLarga: "El Renault Kwid es un vehículo económico y práctico, ideal para moverse por la ciudad. Ofrece un tamaño compacto, un bajo consumo de combustible y un interior versátil para adaptarse a tus necesidades diarias.",
  },
  {
    id: 6,
    marca: "Ford",
    modelo: "EcoSport",
    precio: 32000000,
    añoFabricacion: 2023,
    version: "Freestyle 1.5",
    color: "Gris",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Automática",
    motor: 1.5,
    kilometros: 15000,
    tanque: 52,
    abs: true,
    gnc: false,
    fechaPublicacion: "06/01/2022",
    descripcionCorta: "SUV compacto y versátil.",
    descripcionLarga: "La Ford EcoSport es una SUV compacta y versátil, perfecta para la ciudad y los viajes cortos. Ofrece un diseño moderno, un interior espacioso y una excelente maniobrabilidad.",
  },
  {
    id: 7,
    marca: "Peugeot",
    modelo: "208",
    precio: 24000000,
    añoFabricacion: 2022,
    version: "Allure 1.6",
    color: "Plata",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Manual",
    motor: 1.6,
    kilometros: 8000,
    tanque: 44,
    abs: true,
    gnc: false,
    fechaPublicacion: "07/01/2022",
    descripcionCorta: "Elegante y eficiente para la ciudad.",
    descripcionLarga: "El Peugeot 208 es un automóvil elegante y eficiente, diseñado para la vida urbana. Ofrece un estilo refinado, un interior bien equipado y un excelente rendimiento de combustible.",
  },
  {
    id: 8,
    marca: "Hyundai",
    modelo: "Creta",
    precio: 30000000,
    añoFabricacion: 2023,
    version: "Limited 2.0",
    color: "Blanco",
    combustible: "Nafta",
    puertas: 5,
    transmision: "Automática",
    motor: 2.0,
    kilometros: 12000,
    tanque: 55,
    abs: true,
    gnc: false,
    fechaPublicacion: "08/01/2022",
    descripcionCorta: "SUV moderna y espaciosa.",
    descripcionLarga: "La Hyundai Creta es una SUV moderna y espaciosa, perfecta para familias activas. Ofrece un diseño atractivo, un interior cómodo y bien equipado, y un rendimiento sólido tanto en ciudad como en carretera.",
  },
];