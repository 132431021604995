import React, { useEffect } from "react";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import hero from "../../assets/hero2.png";
import heroBg from "../../assets/herobg.png";
import NewProductsContainer from "../../components/NewProductsContainer";
import CatalogueContainer from "../../components/CatalogueContainer";
import Footer from "../../components/Footer";
import Typed from "typed.js";
import Tabs from "../../components/Tabs";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import newHero from "../../assets/newHero.png";
import { lightGreen } from "@mui/material/colors";
import { Icon } from "@iconify/react/dist/iconify.js";
import styles from "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";

const discounts = [
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  {
    text: "Publicitate acá",
    icon: "streamline:discount-percent-coupon-solid",
  },
  
];

export default function Home() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    // Define las velocidades de escritura y retroceso basadas en el tamaño de la pantalla
    const typeSpeed = isSmallScreen ? 50 : 100;
    const backSpeed = isSmallScreen ? 25 : 50;

    // Añade un carácter especial al frente de cada palabra en las cadenas de texto
    const strings = ["Lo hacemos con pasión", "Vivi la experiencia"];

    // Configura el efecto Typed en los elementos h2 con las velocidades ajustadas
    let typingText = new Typed("#h2-typed", {
      strings: strings,
      loop: true,
      typeSpeed: typeSpeed,
      backSpeed: backSpeed,
      backDelay: 2000,
      showCursor: false,
      smartBackspace: false, // Desactiva el retroceso inteligente
    });

    // Limpia la instancia de Typed cuando el componente se desmonta para evitar fugas de memoria
    return () => {
      typingText.destroy();
    };
  }, [isSmallScreen]);

  const getTitleFontSize = () => {
    return isSmallScreen ? "20px" : "30px";
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // Use useInView to detect when elements are in the viewport
  const [heroRef, heroInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [tabsRef, tabsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [newProductsRef, newProductsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [catalogueRef, catalogueInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Grid
      container
      id="homeContainer"
      sx={{
        background: "linear-gradient(45deg, #010101, #606060)",
      }}
    >
      <Grid
        container
        id="heroContainer"
        sx={{
          height: "100vh",
          backgroundImage: `url(${newHero})`,
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          item
          xs={isSmallScreen ? 12 : 6}
          style={{
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            // backgroundImage: `url(${heroBg})`,
            // backgroundSize: "cover",
          }}
        >
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              height: "100%",
            }}
          >
            <motion.div
              ref={heroRef}
              initial="hidden"
              animate={heroInView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 0.6 }}
            >
              <Typography
                fontSize={50}
                sx={{ textAlign: "left", fontWeight: 600 }}
              >
                <span style={{ color: "#FF9800" }}>
                  MAF <span style={{ color: "#fff" }}>Autos</span>
                </span>{" "}
                <br />
              </Typography>
              <Typography fontSize={30} sx={{ textAlign: "left" }}>
                <span style={{ color: "#FFF" }}>
                  El mejor asesoramiento y precio
                </span>{" "}
                <br />
              </Typography>
              <Typography
                fontSize={50}
                sx={{ textAlign: "left", height: "50px" }}
              >
                <h2
                  id="h2-typed"
                  style={{ fontSize: getTitleFontSize(), marginBottom: 0 }}
                >
                  ''
                </h2>
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        {!isSmallScreen && (
          <Grid
            container
            item
            xs={6}
            id="imagecontainer"
            sx={
              {
                // Puedes añadir estilos adicionales si es necesario
              }
            }
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <img
                src={hero}
                alt="carImage"
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              />
            </motion.div>
          </Grid>
        )}
        <Grid
          container
          sx={{
            bottom: 0,
            height: "10vh",
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {discounts.map((discount, index) => (
              <SwiperSlide key={index} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid
                  className="custom-card"
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ color: "white", width: "20%" }}
                >
                  <Icon icon={discount.icon} width={40} height={40} />
                  <Typography textAlign={"center"}>{discount.text}</Typography>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: "100%",
          height: "2px",
          boxShadow: "0px 0px 30px 0px rgba(255,152,0,0.9)",
        }}
      />
      <motion.div
        ref={tabsRef}
        initial="hidden"
        animate={tabsInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        style={{width: '100%'}}
      >
        <Tabs />
      </motion.div>
      <Divider
        sx={{
          width: "100%",
          height: "2px",
          boxShadow: "0px 0px 30px 0px rgba(255,152,0,0.9)",
        }}
      />
      <motion.div
        ref={newProductsRef}
        initial="hidden"
        animate={newProductsInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        style={{ width: "100%" }}
      >
        <NewProductsContainer />
      </motion.div>
      <Divider
        sx={{
          width: "100%",
          height: "2px",
          boxShadow: "0px 0px 30px 0px rgba(255,152,0,0.9)",
        }}
      />{" "}
      <motion.div
        ref={catalogueRef}
        initial="hidden"
        animate={catalogueInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
      >
        <CatalogueContainer />
      </motion.div>
      <Footer />
    </Grid>
  );
}
