import React from "react";
import { Grid } from "@mui/material";
import "./styles.css";

const ProductSkeleton = () => {
  return (
    <div className="card skeleton">
      <div className="imgContainer"></div>
      <Grid container className="text">
        <div className="skeleton-content"></div>
        <div className="skeleton-buttons"></div>
      </Grid>
    </div>
  );
};

export default ProductSkeleton;
