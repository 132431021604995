import React, { useState } from "react";
import { Button, Grid, Tooltip, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import "./style.css";
import imageNotAvailable from "./No_Image_Available.jpg";
import WPButton from "../../CatalogueContainer/CatalogueCard/wpButton";

const icons = [
  { name: "Motor", icon: "ph:engine" },

  { name: "Puertas", icon: "mingcute:car-door-line" },
  { name: "Color", icon: "material-symbols:colors-sharp" },
  { name: "GNC", icon: "mdi:gas-tank-outline" },
  { name: "Transmision", icon: "solar:transmission-bold" },
  { name: "ABS", icon: "mdi:car-brake-abs" },
];

const NewProductCard = ({ data }) => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleIconHover = (icon) => {
    setHoveredIcon(icon);
  };

  return (
    <div className="card">
      <div className="imgContainer">
        <img
          src={data.imagenes || imageNotAvailable}
          alt={""}
          className="img"
        />
      </div>

      <Grid container className="text" style={{ position: "relative" }}>
        <Icon
          icon="bxs:offer"
          style={{
            width: "40px",
            height: "40px",
            color: "#FF9800",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
        <p className="h3">
          {data?.marca} {data?.modelo} {data?.version}
        </p>
        <Divider
          variant="middle"
          style={{ margin: "5px 0", width: "100%", backgroundColor: "#fff" }}
        />
        <Grid item xs={10} container spacing={2}>
          {icons.map((icon, index) => (
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                key={index}
                title={`${icon.name}: ${
                  icon.name.toLowerCase() === "gnc" ||
                  icon.name.toLowerCase() === "abs"
                    ? data[icon.name.toLowerCase()]
                      ? "Sí"
                      : "No"
                    : data[icon.name.toLowerCase()]
                }`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={icon.icon}
                  onMouseEnter={() => handleIconHover(icon.name)}
                  onMouseLeave={() => handleIconHover(null)}
                  style={{
                    color: hoveredIcon === icon.name ? "#whi" : "white",
                    transform:
                      hoveredIcon === icon.name ? "scale(1.2)" : "scale(1)",
                  }}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Divider
          variant="middle"
          style={{ margin: "5px 0", width: "100%", backgroundColor: "#fff" }}
        />
        <div className="button-container">
          <WPButton
            text={"Mas info"}
            icon={"ic:sharp-whatsapp"}
            color={"#2c2c2c"}
            backgroundColor={"#2c2c2c"}
            textColor={"#ffffff"}
            href={`https://wa.me/5493514033333?text=Hola%20estoy%20interesado%20en%20este%20vehículo%20destacado:%0AMarca:%20${data.marca}%0AModelo:%20${data.modelo}%0AAño:%20${data.anioFabricacion}`}
          />
        </div>
      </Grid>
    </div>
  );
};

export default NewProductCard;
