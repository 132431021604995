import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import NewProductCard from "./NewProductsCard";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ProductService from "../../services/products.service";
import ProductSkeleton from "./ProductSkeleton";

const NewProductsContainer = () => {
  const [loading, setLoading] = useState(true);
  const [newProducts, setNewProducts] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const allProducts = await ProductService.getAllProducts();
        console.log(allProducts);
        const destacados = allProducts.filter(
          (producto) => producto.productoDestacado && producto.productoActivo
        );
        setNewProducts(destacados);
      } catch (error) {
        console.error("Error al obtener los productos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  let slidesPerView = 3; // Valor por defecto para pantallas grandes

  if (isSmallScreen) {
    slidesPerView = 1;
  } else if (isMediumScreen) {
    slidesPerView = 2;
  } else if (isMediumLargeScreen) {
    slidesPerView = 3;
  } else if (isLargeScreen) {
    slidesPerView = 4;
  }

  return (
    <Grid
      item
      container
      xs={12}
      id="newProductsContainer"
      sx={{ justifyContent: "center", margin: "20px 0", padding: "20px 0" }}
    >
      <Grid item xs={10}>
        <Typography
        mb={1}
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "whitesmoke",
            textAlign: "center",
          }}
        >
          Conocé nuestros 0km
        </Typography>
      </Grid>
      <Grid item xs={10} flexDirection="row" sx={{ minHeight: "350px" }}>
        {loading ? ( // Si loading es true, muestra el esqueleto
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            style={{
              padding: "20px 10px",
              "--swiper-navigation-color": "red", // Cambia el color de las flechas
              "--swiper-pagination-color": "blue", // Cambia el color de los puntos de paginación
            }}
            className="mySwiper"
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <SwiperSlide
                key={`placeholder-${index}`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ProductSkeleton />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          // Si loading es false, muestra los productos
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            style={{
              padding: "20px 10px",
              "--swiper-navigation-color": "#ff9800", // Cambia el color de las flechas
              "--swiper-pagination-color": "#ff9800", // Cambia el color de los puntos de paginación
            }}
            className="mySwiper"
          >
            {newProducts?.map((element, i) => (
              <SwiperSlide
                key={element?.id}
                id={`swiperCard${i}`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewProductCard data={element} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Grid>
    </Grid>
  );
};

export default NewProductsContainer;
