import React from "react";
import "./Pagination.css"; // Importa el archivo de estilos CSS

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <p
              onClick={() => paginate(number)}
              className="page-link"
              style={{
                backgroundColor: currentPage === number ? "#FF9800" : "inherit",
                fontWeight: currentPage === number ? "600" : "400",
              }}
            >
              {number}
            </p>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
