import { useState } from "react";
import { Dialog, Grid, Typography, Box } from "@mui/material";
import ProductTable from "../../../components/CustomTable";
import CustomButton from "../../../components/CustomButton";
import ProductForm from "../ProductForm";
import { Icon } from "@iconify/react";
import { mockData } from "../ProductForm/constants";

export default function ProductList() {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tableData, setTableData] = useState(mockData); // State to hold table data

  const handleListItemClick = (value, product = null) => {
    setSelectedProduct(product);
    setOpen(value);
  };

  // Callback function to update table data
  const updateTableData = (newProduct) => {
    // Update the table data with the new product
    setTableData([...tableData, newProduct]);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ padding: "20px", borderRadius: "10px" }}
    >
      <Box
        sx={{
          color: "#007aff",
          textAlign: "center",
          fontWeight: "600",
          padding: "10px",
          width: "100%",
          borderRadius: "10px",
          marginBottom: "20px",
        }}
      >
        <Typography fontWeight={600} variant="h5" color={'primary'}>
          Listado de Publicaciones
        </Typography>
      </Box>
      <Grid item xs={12} alignSelf="flex-end" sx={{marginRight: "50px"}}>
        <CustomButton
          text={"Crear publicación"}
          icon={<Icon icon="mdi:add" />}
          onClick={() => handleListItemClick(true)}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <ProductTable data={tableData} onEdit={handleListItemClick} />{" "}
        {/* Pass updated table data */}
        <Dialog
          id="dialog"
          onClose={() => handleListItemClick(false)}
          open={open}
          sx={{
            ".MuiPaper-root": {
              padding: "40px",
            },
          }}
        >
          <Typography
            id="dialogTitle"
            sx={{ fontSize: "24px", fontWeight: "600" }}
          >
            {selectedProduct ? "Editar" : "Crear"}
          </Typography>
          <ProductForm
            data={selectedProduct}
            onClose={() => handleListItemClick(false)}
            updateTableData={updateTableData}
          />
        </Dialog>
      </Grid>
    </Grid>
  );
}
