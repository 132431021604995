import React from "react";
import { Grid, Skeleton } from "@mui/material";

const CatalogueCardSkeleton = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={10}
      lg={12}
      sx={{
        borderRadius: "8px",
        maxHeight: { lg: "180px" },
        margin: { xs: "20px 0px", md: "20px 10px" },
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        position: "relative",
        maxWidth: { xl: "990px!important" },
      }}
    >
      {/* Contenido del esqueleto */}
      <Grid item md={4} lg={3} className="cardImgContainer" sx={{ width: "100%", height: { xs: '300px', md: '100%', xl: '180px'} }}>
        <Skeleton variant="rectangular" width="100%" height="100%" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
      </Grid>
      <Grid container item md={8} lg={9} padding="16px">
        <Grid container item xs={12} style={{ height: { md: "70px" } }}>
          <Grid
            container
            item
            md={12}
            lg={5}
            sx={{
              flexDirection: { md: "row", lg: "column" },
              justifyContent: { xs: "space-between", lg: "start" },
              alignItems: { xs: "center", lg: "start" },
              paddingBottom: { xs: "10px", sm: "0px" },
            }}
          >
            <Skeleton variant="text" sx={{width: {xs: '60%', md: '40%' }}}/>
            <Skeleton variant="text" sx={{width: {xs: '20%' }}} />
          </Grid>
          <Grid
            container
            item
            md={12}
            lg={7}
            sx={{ paddingTop: { sm: "10px" }, justifyContent: "center" }}
          >
            {/* Muestra los iconos como esqueletos */}
            {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
              <Grid
                item
                xs={4}
                sm={3}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: { xs: "10px" },
                }}
              >
                <Skeleton variant="circle" width={40} height={40} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          justifyContent={"end"}
          alignItems={"end"}
          sx={{ marginTop: { xs: "10px",  } }}
        >
          {/* Agrega esqueletos para los botones */}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md={6}
            lg={6}
            style={{ padding: "0px", justifyContent: "end" }}
          >
            <Grid item>
              <Skeleton variant="rectangular" width={100} height={40} style={{borderRadius: '8px'}}/>
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={100} height={40} style={{borderRadius: '8px'}}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CatalogueCardSkeleton;
