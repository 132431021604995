import React, { useState, useEffect } from "react";
import { IconButton, Snackbar, Button, TextField, Dialog } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ProductService from "../../services/products.service";
import ProductForm from "../../pages/Admin/ProductForm";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon } from "@iconify/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./style.css";

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [deleteProductName, setDeleteProductName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filters, setFilters] = useState({ name: "", brand: "", patent: "" }); // Agregar estado para los filtros
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const fetchProducts = async () => {
    try {
      const productList = await ProductService.getAllProducts();
      setProducts(productList);
      console.log(productList);
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleDeleteProduct = (productId, productName) => {
    setDeleteProductId(productId);
    setDeleteProductName(productName);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmDeleteOpen(false);
    try {
      await ProductService.deleteProduct(deleteProductId);
      setProducts(
        products.filter((product) => product._id !== deleteProductId)
      );
      setSnackbarMessage(`El producto ${deleteProductName} ha sido eliminado.`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error al eliminar producto:", error);
      setSnackbarMessage(
        "Error al eliminar producto. Por favor, inténtalo de nuevo más tarde."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    fetchProducts();
    setSelectedProduct(null);
    setOpenDialog(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageDialogOpen(true);
  };

  // Apply filters
  const filteredProducts = products.filter((product) => {
    return (
      (!filters.name ||
        product.nombre.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.brand ||
        product.marca.toLowerCase().includes(filters.brand.toLowerCase())) &&
      (!filters.patent ||
        product.patente.toLowerCase().includes(filters.patent.toLowerCase()))
    );
  });

  // Paginate filtered products
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <TextField
          label="Nombre"
          variant="standard"
          name="name"
          value={filters.name}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Marca"
          variant="standard"
          name="brand"
          value={filters.brand}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Patente"
          variant="standard"
          name="patent"
          value={filters.patent}
          onChange={handleFilterChange}
        />
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Nombre</Th>
            <Th>Marca</Th>
            <Th>Modelo</Th>
            <Th>Patente</Th>
            <Th>Precio</Th>
            <Th>Publicacion Destacado</Th>
            <Th>Publicacion Activo</Th>
            <Th>Fecha de Publicación</Th>
            <Th>Imagen</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentProducts.map((element) => (
            <Tr key={element._id}>
              <Td>{element.nombre}</Td>
              <Td>{element.marca}</Td>
              <Td>{element.modelo}</Td>
              <Td>{element.patente}</Td>
              <Td>{element.precio}</Td>
              <Td style={{ textAlign: "center" }}>
                {element.productoDestacado ? (
                  <Icon
                    icon="mingcute:check-circle-fill"
                    color="green"
                    style={{ fontSize: 24 }}
                  />
                ) : (
                  <Icon
                    icon="mingcute:close-circle-fill"
                    color="red"
                    style={{ fontSize: 24 }}
                  />
                )}
              </Td>
              <Td style={{ textAlign: "center" }}>
                {element.productoActivo ? (
                  <Icon
                    icon="mingcute:check-circle-fill"
                    color="green"
                    style={{ fontSize: 24 }} // Ajusta el tamaño del icono
                  />
                ) : (
                  <Icon
                    icon="mingcute:close-circle-fill"
                    color="red"
                    style={{ fontSize: 24 }} // Ajusta el tamaño del icono
                  />
                )}
              </Td>

              <Td>{formatDate(element.fechaCarga)}</Td>
              <Td>
                {element.imagenes.length > 0 && (
                  <img
                    src={element.imagenes[0]}
                    alt="Thumbnail"
                    style={{ width: "50px", height: "50px", cursor: "pointer" }} // Agrega cursor:pointer para indicar que es clickeable
                    onClick={() => handleImageClick(element.imagenes[0])} // Maneja el clic en la imagen
                  />
                )}
              </Td>
              <Td>
                <IconButton
                  aria-label="Editar"
                  style={{ color: "blue" }}
                  onClick={() => handleEditProduct(element)}
                >
                  <Icon icon="mdi:edit" />
                </IconButton>
                <IconButton
                  aria-label="Eliminar"
                  style={{ color: "red" }}
                  onClick={() =>
                    handleDeleteProduct(
                      element._id,
                      `${element.marca} ${element.modelo}`
                    )
                  }
                >
                  <Icon icon="mingcute:delete-line" />
                </IconButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </Button>
        <Button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastItem >= filteredProducts.length}
        >
          Siguiente
        </Button>
      </div>
      <Dialog
        id="dialog"
        onClose={handleCloseDialog}
        open={openDialog}
        sx={{
          ".MuiPaper-root": {
            padding: "40px",
          },
        }}
      >
        <DialogTitle>{selectedProduct ? "Editar" : "Crear"}</DialogTitle>
        <DialogContent>
          <ProductForm
            data={selectedProduct}
            onClose={handleCloseDialog}
            updateTableData={(response) => {
              if (response) {
                setProducts([...products, response]);
              }
              handleCloseDialog();
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar Producto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar el Producto{" "}
            <strong>{deleteProductName}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
      >
        <DialogContent>
          <img
            src={selectedImage}
            alt="Large"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ProductTable;
