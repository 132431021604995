import Admin from "../pages/Admin";
import CarList from "../pages/Admin/ProductsList";
import UserList from "../pages/Admin/UserList";
import CarDetailsPage from "../components/CarDetailsPage";

const privateRoutes = [
  {
    path: "/admin",
    route: "admin",
    component: Admin,
  },
  {
    path: "/car-list",
    route: "carList",
    component: CarList,
  },
  {
    path: "/user-list",
    route: "userList",
    component: UserList,
  },
  {
    path: "/car-details/:id", // Define la ruta para los detalles del automóvil con un parámetro de id
    route: "carDetails",
    component: CarDetailsPage,
  },
];

export default privateRoutes;
