import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import { Icon } from "@iconify/react";
import {
  Box,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  AppBar,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { setActiveTab } from "../../store/tabSlice";

export default function Layout({ children }) {
  const [open, setOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    const storedFullName = localStorage.getItem("fullName");
    if (storedFullName) {
      setFullName(storedFullName);
    }
  }, [isAuthenticated]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleTabChange = (tabValue) => {
    dispatch(setActiveTab(tabValue));
  };

  return (
    <Grid
      data-testid="layout-test"
      sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <AppBar position="static" id='este es el appbar' style={{position: "absolute", zIndex: 3000, backgroundColor: 'transparent'}}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: 'relative'
          }}
        >
          {(isAuthenticated || isSmallScreen) && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <Icon icon="iconamoon:menu-burger-horizontal-fill" style={{color: 'black'}}/>
            </IconButton>
          )}
          <img
            src={logo}
            alt="maf-logo"
            width={"60px"}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />

          {location.pathname === "/" && (
            <Grid sx={{ display: { xs: "none", lg: "flex" } }}>
              <a
                href="#newProductsContainer"
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "0px 10px",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                0Km
              </a>
              <a
                href="#catalogue"
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "0px 10px",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Usados
              </a>
              <a
                href="#tabs"
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "0px 10px",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
                onClick={() => handleTabChange("1")}
              >
                Sobre nosotros
              </a>
              <a
                href="#tabs"
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "0px 10px",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
                onClick={() => handleTabChange("2")}
              >
                Financiación
              </a>
            </Grid>
          )}
          {isAuthenticated ? (
            <Tooltip title="Cerrar sesión">
              <Icon
                icon="solar:logout-3-line-duotone"
                onClick={handleLogout}
                style={{ cursor: "pointer", width: "32px", height: "32px", color: '#000' }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Iniciar sesión">
              <Icon
                icon="solar:login-3-line-duotone"
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer", width: "32px", height: "32px" }}
              />
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          sx={{
            "& .MuiPaper-root": {
              width: "250px",
              backgroundColor: '#414141'
            },
          }}
          open={open}
          onClose={toggleDrawer(false)}
        >
          {isAuthenticated && (
            <>
              <ListItem disablePadding>
                <ListItemButton>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Icon
                        icon="ic:baseline-account-circle"
                        style={{ fontSize: 48, color: '#fff' }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{color: 'white'}} variant="subtitle1" align="center">
                        {fullName}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                onClick={() => {
                  navigate("/admin");
                  setOpen(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon icon="ri:home-fill" width={"24px"} height={"24px"} style={{color: '#fff'}}/>
                  </ListItemIcon>
                  <ListItemText style={{color: 'white'}} primary={"Dashboard de Administrador"} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("/car-list");
                  setOpen(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon
                      icon="material-symbols-light:list-alt"
                      width={"24px"}
                      height={"24px"}
                      style={{color: '#fff'}}
                    />
                  </ListItemIcon>
                  <ListItemText style={{color: 'white'}} primary={"Listado de publicaciones"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("/user-list");
                  setOpen(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon
                      icon="ph:user-list-fill"
                      width={"24px"}
                      height={"24px"}
                      style={{color: '#fff'}}
                    />
                  </ListItemIcon>
                  <ListItemText style={{color: 'white'}} primary={"Listado de Usuarios"} />
                </ListItemButton>
              </ListItem>
            </>
          )}
          <Divider />
          {location.pathname === "/" && (
            <Grid
              container
              spacing={2}
              mt="20px"
              padding={"0px 30px"}
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <Grid item xs={12}>
                <a
                  href="#newProductsContainer"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  0Km
                </a>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <a
                  href="#catalogue"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Usados
                </a>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <a
                  href="#tabs"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  onClick={() => handleTabChange("1")}
                >
                  Sobre nosotros
                </a>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <a
                  href="#tabs"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  onClick={() => handleTabChange("2")}
                >
                  Financiación
                </a>
              </Grid>
            </Grid>
          )}
        </Drawer>
      </Box>
      <Grid container id="main" sx={{ height: "100vh" }}>
        {children}
      </Grid>
    </Grid>
  );
}
