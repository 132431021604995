import { Grid, Typography } from "@mui/material";
import image from "../../assets/office.jpg";

const AboutUs = () => {
  return (
    <Grid container justifyContent="center" sx={{padding: '0!important'}} >
      <Grid container justifyContent={"center"} item xs={12} md={6} sx={{padding: '0!important'}}>
        <Grid item xs={12} sx={{padding: '0!important'}}>
          <img
            src={image}
            alt="imag"
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent={"center"}
        xs={12}
        md={6}
      >
        <Grid item xs={12} lg={11}>
          <Typography
            color={"#fff"}
            sx={{ fontSize: "20px", lineHeight: '2.5' }}
          >
            En MAF creemos en la transparencia, la confianza y la
            satisfacción del cliente. Por eso, nos aseguramos de ofrecer un
            servicio personalizado, donde cada cliente reciba la atención que se
            merece y encuentre el automóvil perfecto que se adapte a sus
            necesidades y presupuesto.
             <br/><br/>Nuestra filosofía se centra en brindar a nuestros clientes una
            alternativa sencilla y sin complicaciones para adquirir su próximo
            vehículo. 
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
