import { Grid, Tooltip, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Ribbon, RibbonContainer } from "react-ribbons";
import WPButton from "./wpButton";
import "./styles.css"; // Importa tu hoja de estilos CSS
import Swal from 'sweetalert2';
const CatalogueCard = ({ data }) => {
  // Función para formatear el número del motor
  const formatMotor = (motor) => {
    if (motor && Number.isInteger(parseFloat(motor))) {
      return motor + ".0";
    }
    return motor;
  };

 const handleShare = () => {
  const url = `https://mafautos.com/car-details/${data._id}`;
  const shareText = ``;
  
  if (navigator.share) {
    navigator.share({
      title: 'Compartir este vehículo',
      text: shareText,
      url: url,
    })
    .then(() => console.log('Compartido exitosamente'))
    .catch((error) => console.error('Error al compartir', error));
  } else {
    // Fallback para navegadores que no soportan la API de compartir
    console.log("La función de compartir no está soportada en este navegador.");
    Swal.fire({
      icon: 'info',
      title: 'Compartir no soportado',
      text: 'Lo siento, la función de compartir no está disponible en este navegador.',
    });
  }
};
  
  // Array de íconos con su respectivo texto y classname
  const icons = [
    { name: "Motor", icon: "ph:engine", text: formatMotor(data?.motor) },

    { name: "Puertas", icon: "mingcute:car-door-line", text: data?.puertas },
    { name: "Color", icon: "material-symbols:colors-sharp", text: data?.color },
    {
      name: "GNC",
      icon: "mdi:gas-tank-outline",
      text: data?.gnc ? "Si" : "No",
    },
    {
      name: "Transmision",
      icon: "solar:transmission-bold",
      text: data?.transmision,
    },
    { name: "ABS", icon: "mdi:car-brake-abs", text: data?.abs ? "Si" : "No" },
   
  ];

  // Estado para manejar el hover de cada ícono
  const [hoveredIcons, setHoveredIcons] = useState(
    Array.from({ length: icons.length }, () => false)
  );

  // Función para manejar el hover de un ícono específico
  const handleIconHover = (index, isHovered) => {
    setHoveredIcons((prevState) =>
      prevState.map((hovered, i) => (i === index ? isHovered : hovered))
    );
  };
  const currentURL = window.location.href;
  console.log(currentURL);
  return (
    <Grid
      item
      id="catalogueCardContainer"
      container
      xs={12}
      sm={10}
      lg={12}
      sx={{
        borderRadius: "8px",
        maxHeight: { lg: "180px" },
        margin: { xs: "20px 0px", md: "20px 10px" },

        boxShadow: " inset 0px -75px 50px -65px rgba(255,152,0,0.4)",
        // boxShadow:
        //   "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        position: "relative",
        maxWidth: { xl: "990px!important" },
        background: "rgba(72,75,92,0.15)",
        WebkitBackdropFilter: "blur(7px)",
        backdropFilter: "blur(7px)",
      }}
    >
      {" "}
      <Grid
        id="listonContainer"
        style={{
          position: "absolute",
          zIndex: "100",
          ". span": { fontWeight: "600!important" },
        }}
      >
        <RibbonContainer>
          <Ribbon
            id="liston"
            side="left"
            type="corner"
            size="normal"
            backgroundColor="#000"
            color="white"
            fontFamily="sans"
            withStripes={true}
          >
            <span style={{ fontWeight: "700", fontSize: "16px" }}>
              {data?.anioFabricacion}
            </span>
          </Ribbon>
        </RibbonContainer>
      </Grid>
      <Grid
        item
        md={4}
        lg={3}
        className="cardImgContainer"
        style={{ width: "100%", height: { xs: "300px" } }}
      >
        <img
          src={data?.imagenes}
          alt=""
          className="cardImage"
          style={{ display: "block", maxWidth: "100%", margin: "auto" }}
        />
      </Grid>
      <Grid container item md={8} lg={9} padding="16px">
        <Grid container item xs={12} style={{ height: { md: "70px" } }}>
          <Grid
            container
            item
            md={12}
            lg={5}
            sx={{
              flexDirection: { md: "row", lg: "column" }, // Cambia la dirección de los elementos en columnas para xs y en filas para lg
              justifyContent: { xs: "space-between", lg: "start" }, // Justifica los elementos alrededor en md
              alignItems: { xs: "center", lg: "start" }, // Alinea los elementos al centro en md
              paddingBottom: { xs: "10px", sm: "0px" },
            }}
          >
            <Typography fontSize={20} fontWeight={600} color="whitesmoke">
              {data?.nombre}
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="whitesmoke">
              {data?.kilometros} km
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={12}
            lg={7}
            sx={{ paddingTop: { sm: "10px" }, justifyContent: "center" }}
          >
            {icons.map((icon, index) => (
              <Grid
                item
                xs={4}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: { xs: "10px", md: "0px" },
                }}
              >
                <Tooltip
                  title={icon.name}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    onMouseEnter={() => handleIconHover(index, true)}
                    onMouseLeave={() => handleIconHover(index, false)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      icon={icon.icon}
                      width={"20px"}
                      height={"20px"}
                      style={{
                        marginRight: "5px",
                        color: hoveredIcons[index] ? "#FF9800" : "whitesmoke",
                        transform: hoveredIcons[index]
                          ? "scale(1.2)"
                          : "scale(1)",
                      }}
                    />
                    <Typography
                      fontSize={12}
                      style={{
                        fontSize: hoveredIcons[index] ? "14px" : "12px",
                        color: hoveredIcons[index] ? "#FF9800" : "whitesmoke",
                      }}
                    >
                      {icon.text}
                    </Typography>
                  </div>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sx={{
            display: { xs: "none", md: "flex" },
            paddingTop: { md: "20px" },
          }}
        >
          {data?.descripcionCorta}
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          spacing={3}
          justifyContent={"end"}
          alignItems={"end"}
          sx={{ marginTop: { xs: "10px", md: "0px" } }}
        >
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md={6}
            lg={6}
            style={{ padding: "0px", justifyContent: "end" }}
          >
            {/* <Grid item>
              <WPButton
                text={"Compartir"}
                icon={"ic:baseline-share"}
                color={"#2c2c2c"}
                backgroundColor={"#2c2c2c"}
                textColor={"#ffffff"}
                target='_blank'
                href={`https://wa.me/5493517429772?text=Hola%20estoy%20interesado%20en%20este%20vehículo:%0AMarca:%20${data.marca}%0AModelo:%20${data.modelo}%0AAño:%20${data.anioFabricacion}%0Ahttps://mafautos.com//car-details/${data._id}`}
              />
            </Grid> */}
            <Grid item>
              <WPButton
                text={"Compartir"}
                icon={"ic:baseline-share"}
                color={"#2c2c2c"}
                backgroundColor={"#2c2c2c"}
                textColor={"#ffffff"}
                onClick={handleShare}
              />
            </Grid>
            <Grid item>
              <WPButton
                text={"Mas info"}
                icon={"ic:sharp-whatsapp"}
                color={"#2c2c2c"}
                backgroundColor={"#2c2c2c"}
                textColor={"#ffffff"}
                href={`https://wa.me/5493514033333?text=Hola%20estoy%20interesado%20en%20este%20vehículo:%0AMarca:%20${data.marca}%0AModelo:%20${data.modelo}%0AAño:%20${data.anioFabricacion}`}
                target="_blank"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CatalogueCard;
