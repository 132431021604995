import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProductService from "../../services/products.service";
import AuthService from "../../services/auth.service";
import { Pie } from "react-chartjs-2";
import "./dashboard.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const Admin = () => {
  const [productsActivesCount, setProductActivesCount] = useState(0);
  const [productsNoActivesCount, setProductNoActivesCount] = useState(0);
  const [featuredProductCount, setFeaturedProductCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    fetchDataFromAPI();
    fetchUserDataFromAPI();
  }, []);

  const fetchDataFromAPI = async () => {
    try {
      const products = await ProductService.getAllProducts();
      const featuredProducts = products.filter(
        (product) => product.productoDestacado
      );
      const productsActives = products.filter(
        (product) => product.productoActivo
      );
      const productsNoActives = products.filter(
        (product) => !product.productoActivo
      );
      setProductActivesCount(productsActives.length);
      setProductNoActivesCount(productsNoActives.length);
      setFeaturedProductCount(featuredProducts.length);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchUserDataFromAPI = async () => {
    try {
      const users = await AuthService.getAllUsers();
      setUserCount(users.length);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const data = {
    labels: [
      "Publicaciones Activas",
      "Publicaciones No Activas",
      "Publicaciones Destacadas",
    ],
    datasets: [
      {
        label: "Cantidad",
        data: [
          productsActivesCount,
          productsNoActivesCount,
          featuredProductCount,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const fontSizeTitle = isSmallScreen ? "h6" : "h5";
  const fontSizeSubtitle = isSmallScreen ? "body2" : "body1";

  let gridColumns = 12;

  if (isSmallScreen) {
    gridColumns = 12;
  } else if (isMediumScreen) {
    gridColumns = 10;
  } else if (isMediumLargeScreen) {
    gridColumns = 8;
  } else if (isLargeScreen) {
    gridColumns = 12;
  }

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems="center"
      mt={8}
      sx={{ padding: "20px", borderRadius: "10px" }}
    >
      <Grid item container xs={gridColumns}>
        <Box
          sx={{
            color: "#007aff",
            textAlign: "center",
            fontWeight: "600",
            padding: "10px",
            width: "100%",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          <Typography
            fontWeight={600}
            variant={fontSizeTitle}
            color={"primary"}
          >
            Dashboard de Administrador
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item container spacing={3} xs={12} md={8}>
            <Grid item xs={12} md={6} lg={3}>
              <Card className="dashboard-card">
                <div className="dashboard-bar"></div>
                <CardContent className="card-content">
                  <Stack spacing={3}>
                    <Typography
                      color="text.secondary"
                      variant={fontSizeSubtitle}
                    >
                      Publicaciones Activas
                    </Typography>
                    <Typography variant="h4" textAlign="end">
                      {productsActivesCount}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className="dashboard-card">
                <div className="dashboard-bar"></div>
                <CardContent className="card-content">
                  <Stack spacing={3}>
                    <Typography
                      color="text.secondary"
                      variant={fontSizeSubtitle}
                    >
                      Publicaciones No Activas
                    </Typography>
                    <Typography variant="h4" textAlign="end">
                      {productsNoActivesCount}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className="dashboard-card">
                <div className="dashboard-bar"></div>
                <CardContent className="card-content">
                  <Stack spacing={3}>
                    <Typography
                      color="text.secondary"
                      variant={fontSizeSubtitle}
                    >
                      Publicaciones Destacadas
                    </Typography>
                    <Typography variant="h4" textAlign="end">
                      {featuredProductCount}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className="dashboard-card">
                <div className="dashboard-bar"></div>
                <CardContent className="card-content">
                  <Stack spacing={3}>
                    <Typography
                      color="text.secondary"
                      variant={fontSizeSubtitle}
                    >
                      Usuarios
                    </Typography>
                    <Typography variant="h4" textAlign="end">
                      {userCount}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            sx={{
              color: "#007aff",
              textAlign: "center",
              fontWeight: "600",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    Cantidad de Publicaciones
                  </Typography>
                  <Pie data={data} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Admin;
