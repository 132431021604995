import { Grid, Typography, Tab, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AboutUs from "../AboutUS/index";
import { setActiveTab } from "../../store/tabSlice";
import { useDispatch, useSelector } from "react-redux";

const Tabs = () => {

  const activeTab = useSelector((state) => state.tab.activeTab);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
  };

  return (
    <Grid container sx={{ width: "100%" }} id="tabs">
      <TabContext value={activeTab} centered>
        <Grid container justifyContent={"center"}>
          <TabList
            sx={{
              paddingBottom: "30px",

              "& .MuiTab-root": {
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#FF9800",
                  color: "#fff",
                },
              },
              "& .Mui-selected": {
                color: "#FF9800!important",
                borderBottom: `5px solid #FF9800`,
                "&:hover": {
                  backgroundColor: "#FF9800",
                  color: "#fff!important",
                },
              },
            }}
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              label="Sobre Nosotros"
              sx={{ fontSize: "24px", fontWeight: "500", textTransform: 'none' }}
              value="1"
            />
            <Divider
              orientation="vertical"
              sx={{
                width: "2px",
                height: "70px",
                boxShadow: "0px 0px 30px 0px rgba(255,152,0,0.9)",
              }}
            />
            <Tab
              label="Financiación"
              sx={{ fontSize: "24px", fontWeight: "500", textTransform: 'none' }}
              value="2"
            />
          </TabList>
        </Grid>
        <TabPanel value="1" sx={{ padding: "0!important" }}>
          <div id="about-us-section" >

          <AboutUs />
          </div>
        </TabPanel>
        <TabPanel sx={{ width: "100%" }} value="2">
          <Grid
          id={'finance-section'}
            container
            sx={{
              minHeight: "400px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={'24px'} fontWeight={500} color={"white"}>¡Próximamente...!</Typography>
          </Grid>
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default Tabs;
