import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem, Tooltip } from "@mui/material";
import WPButton from "../CatalogueCard/wpButton";
import { Icon } from "@iconify/react";

function obtenerValoresUnicos(data) {
  const marcasSet = new Set();
  const añosFabricacionSet = new Set();
  const modelosSet = new Set();

  console.log('data', data);
  
  data.forEach((item) => {
    marcasSet.add(item.marca);
    añosFabricacionSet.add(item.anioFabricacion);
    modelosSet.add(item.modelo);
  });

  marcasSet.add("Todos");
  añosFabricacionSet.add("Todos");
  modelosSet.add("Todos");

  const marcas = Array.from(marcasSet);
  let añosFabricacion = Array.from(añosFabricacionSet);
  const modelos = Array.from(modelosSet);
  
  añosFabricacion = añosFabricacion.filter(anio => anio !== "Todos").sort((a, b) => b - a);
  añosFabricacion.unshift("Todos");
  return { marcas, añosFabricacion, modelos };
}

const CatalogueFilter = ({ setDataFiltered, data }) => {
  const [marca, setMarca] = useState("Todos");
  const [modelo, setModelo] = useState("Todos");
  const [añoFabricacion, setAñoFabricacion] = useState("Todos");

  const [filterValues, setFilterValues] = useState({});

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const marcaValida = marca === "Todos" || item.marca === marca;
      const modeloValido = modelo === "Todos" || item.modelo === modelo;
      const añoValido =
        añoFabricacion === "Todos" ||
        item.anioFabricacion.toString() === añoFabricacion.toString();
      return marcaValida && modeloValido && añoValido;
    });
    setDataFiltered(filteredData);
  };

  const handleReset = () => {
    setMarca("Todos");
    setModelo("Todos");
    setAñoFabricacion("Todos");
    setDataFiltered(data);
  };

  useEffect(() => {
    const filterValue = obtenerValoresUnicos(data);
    setFilterValues(filterValue);
  }, [data]);

  const iconStyles = {
    width: "24px",
    height: "24px",
    color: "red",
    cursor: "pointer",
    transition: "transform 0.3s",
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "scale(1.2)";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "scale(1)";
  };

  return (
    <Grid container item xs={12} alignItems="center" justifyContent={"center"}>
      <Grid item container spacing={2} xs={10} md={8}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Marca"
            fullWidth
            variant="outlined"
            select
            value={marca}
            onChange={(e) => setMarca(e.target.value)}
            sx={{
              "& .MuiInputBase-root": { color: "#ff9800" },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ff9800", // Color del borde del campo
                },
                "&:hover fieldset": {
                  borderColor: "#ff9800", // Color del borde al pasar el mouse
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff9800", // Color del borde cuando está enfocado
                },
              },
              "& .MuiSelect-icon": {
                color: "white",
              },
              "& .MuiMenuItem-root": {
                color: "white",
              },
            }}
          >
            {filterValues?.marcas?.map((opcion) => (
              <MenuItem key={opcion} value={opcion}>
                {opcion}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Modelo"
            fullWidth
            variant="outlined"
            select
            value={modelo}
            onChange={(e) => setModelo(e.target.value)}
            sx={{
              "& .MuiInputBase-root": { color: "#ff9800" },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ff9800", // Color del borde del campo
                },
                "&:hover fieldset": {
                  borderColor: "#ff9800", // Color del borde al pasar el mouse
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff9800", // Color del borde cuando está enfocado
                },
              },
              "& .MuiSelect-icon": {
                color: "white",
              },
              "& .MuiMenuItem-root": {
                color: "white",
              },
            }}
          >
            {filterValues?.modelos?.map((opcion) => (
              <MenuItem key={opcion} value={opcion}>
                {opcion}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Año de Fabricación"
            fullWidth
            variant="outlined"
            select
            value={añoFabricacion}
            onChange={(e) => setAñoFabricacion(e.target.value)}
            sx={{
              "& .MuiInputBase-root": { color: "#ff9800" },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ff9800", // Color del borde del campo
                },
                "&:hover fieldset": {
                  borderColor: "#ff9800", // Color del borde al pasar el mouse
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#ff9800", // Color del borde cuando está enfocado
                },
              },
              "& .MuiSelect-icon": {
                color: "white",
              },
              "& .MuiMenuItem-root": {
                color: "white",
              },
            }}
          >
            {filterValues?.añosFabricacion?.map((opcion) => (
              <MenuItem key={opcion} value={opcion}>
                {opcion}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          container
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Tooltip title="Limpiar filtros">
            <Icon
              icon="mingcute:delete-line"
              style={iconStyles}
              onClick={handleReset}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>
          <WPButton
            text={"Filtrar"}
            icon={"clarity:filter-solid"}
            color={"#404040"}
            anchor={true}
            onClick={handleFilter}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CatalogueFilter;
