import { createSlice } from '@reduxjs/toolkit';

const tabSlice = createSlice({
  name: 'tab',
  initialState: {
    activeTab: '1' // Valor inicial para la pestaña activa
  },
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    }
  }
});

export const { setActiveTab } = tabSlice.actions;
export default tabSlice.reducer;