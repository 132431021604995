import React, { useState } from "react";
import { Icon } from "@iconify/react";

const WPButton = ({ text, icon, color, backgroundColor, textColor, anchor = false, onClick, href }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    backgroundColor: backgroundColor || "#fff",
    border: `1px solid ${color}`,
    textTransform: "none",
    padding: "8px",
    transition: "0.5s",
    fontSize: "14px", 
    display: "flex",
    borderRadius: "0.4em",
    cursor: "pointer",
    ...(isHovered && {
      backgroundColor: color,
    }),
    alignItems: "center",
    height: "fit-content",
    textDecoration: "none",
    color: textColor || color, // Establece el color del texto, usando el prop textColor si está definido, de lo contrario, utiliza el color del botón
  };

  const textStyle = {
    display: "block",
    margin: "0px",
    transition: ".5s",
    color: isHovered ? "#fff" : buttonStyle.color, // Utiliza el color del botón cuando está resaltado
  };

  const svgStyle = {
    display: "block",
    width: "16px", 
    height: "16px", 
    margin: "0",
    marginLeft: "5px",
    padding: "0",
    color: isHovered ? "#fff" : buttonStyle.color, // Utiliza el color del botón cuando está resaltado
  };

  if (window.innerWidth >= 900) {
    svgStyle.color = "#fff";
    if (!anchor) {
      svgStyle.display = isHovered ? "block" : "none";
    }
  }
  if (anchor) {
    svgStyle.color = isHovered ? '#fff' : buttonStyle.color; // Utiliza el color del botón cuando está resaltado
  }

  return (
    <a
      href={href}
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <p style={textStyle}>{text}</p>
      <Icon
        icon={icon}
        width="20"
        height="20"
        fill="currentColor"
        style={svgStyle}
      />
    </a>
  );
};

export default WPButton;
