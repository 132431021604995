import { Grid, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import CatalogueCard from "./CatalogueCard";
import Filters from "./FIlters";
import NoResultsMessage from "./noFilterResults";
import ProductService from "../../services/products.service";
import CatalogueCardSkeleton from "./CatalogueCardSkeleton";
import Pagination from "./Pagination";



const AnimatedCount = ({ finalValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Comienza a incrementar el contador
          let currentCount = 0;
          const interval = setInterval(() => {
            if (currentCount <= finalValue) {
              setCount(currentCount);
              currentCount++;
            } else {
              clearInterval(interval);
            }
          }, 50); // Intervalo de tiempo para la animación de conteo

          // Detener el observer después de comenzar la animación
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(document.getElementById("count-animation-trigger")); // Observa este elemento para comenzar la animación
    
    return () => {
      observer.disconnect(); // Detener el observer cuando el componente se desmonta
    };
  }, [finalValue]);

  return (
    <Typography fontSize={'24px'} fontWeight={500} color="white" id="count-animation-trigger" mb={3}>
      Hoy contamos con <span style={{fontWeight: '600', color: '#FF9800'}}>{count}</span> usados seleccionados
    </Typography>
  );
};



const CatalogueContainer = () => {
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([])
  const [dataFiltered, setDataFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const catalogueContainerRef = useRef(null);
 
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const products = await ProductService.getAllProducts();
        const productsActivos = products.filter(
          (producto) => producto.productoActivo && !producto.productoDestacado
        );
        setOriginalData(productsActivos)
        setDataFiltered(productsActivos);
      } catch (error) {
        console.error("Error al obtener los productos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Llamada a la función fetchData al montar el componente
  }, []); // El segundo argumento de useEffect como un array vacío asegura que la solicitud se realice solo una vez al montar el componente
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Hacer scroll suave hasta el inicio de la sección
    if (catalogueContainerRef.current) {
      catalogueContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Calculate current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataFiltered.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Grid
      container
      sx={{ justifyContent: "center",  margin: "20px 0", padding: "20px 0" }}
      spacing={0}
      id='catalogue'
    >
      <Grid item container sm={12} justifyContent={'center'}>
        <AnimatedCount finalValue={originalData.length} />
      </Grid>
      <Grid
        item
        container
        sm={12}
        pb={"20px"}
        sx={{ maxWidth: { xl: "1100px!important" } }}
      >
        <Filters
          dataFiltered={dataFiltered}
          setDataFiltered={setDataFiltered}
          data={originalData}
        />
      </Grid>
      <Grid
        id="cardContainer"
        item
        container
        xs={8}
        sm={12}
        lg={9}
        sx={{
          padding: { xs: "0px!important", sm: "30px !important" },
          justifyContent: { sm: "center" },
        }}
        ref={catalogueContainerRef}
      >
        {loading ? (
          Array.from({ length: itemsPerPage }).map((_, index) => (
            <CatalogueCardSkeleton key={index} />
          ))
        ) : currentItems.length > 0 ? (
          currentItems.map((element, i) => (
            <CatalogueCard key={i} data={element} />
          ))
        ) : (
          <NoResultsMessage />
        )}
          
      </Grid>
      <Grid item xs={12}>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={dataFiltered.length}
          paginate={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </Grid>
  );
};

export default CatalogueContainer;
