import React, { useState } from "react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./constants";
import {
  Button,
  CardActions,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Snackbar,
  Typography,
} from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { Icon } from "@iconify/react";
import { FileUploader } from "react-drag-drop-files";
import ProductService from "../../../services/products.service";
import MuiAlert from "@mui/material/Alert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const fileTypes = ["jpg", "jpeg", "png", "gif"];

const steps = [
  "Detalles del producto",
  "Detalles adicionales",
  "Imágenes del producto",
];

const ImagePreview = ({ files, handleDelete }) => {
  return (
    <Grid item xs={12} container spacing={2}>
      {files.map((file, index) => (
        <Grid xs={3} item key={index}>
          <CardMedia
            component="img"
            height="80"
            width="80"
            image={URL.createObjectURL(file)}
            alt={`Imagen ${index}`}
          />
          <CardActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="small"
              color="error"
              onClick={() => handleDelete(index)}
            >
              Eliminar
            </Button>
          </CardActions>
        </Grid>
      ))}
    </Grid>
  );
};

const ImagePreviews = ({ images, handleDelete }) => {
  return (
    <Grid item xs={12} container spacing={2}>
      {images.map((imageUrl, index) => (
        <Grid xs={3} item key={index}>
          <CardMedia
            component="img"
            height="80"
            width="80"
            image={imageUrl}
            alt={`Imagen ${index}`}
          />
          <CardActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="small"
              color="error"
              onClick={() => handleDelete(index)}
            >
              Eliminar
            </Button>
          </CardActions>
        </Grid>
      ))}
    </Grid>
  );
};

const MySwal = withReactContent(Swal);

const ProductForm = ({ data = null, updateTableData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const formik = useFormik({
    initialValues: data?._id ? data : initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const [checkboxValues, setCheckboxValues] = useState({
    abs: formik.values.abs || false,
    gnc: formik.values.gnc || false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleChange = (fileList) => {
    const newFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      newFiles.push(fileList[i]);
    }
    setFiles([...files, ...newFiles]);
  };

  const handleDelete = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleNext = async () => {
    // Obtener los campos relevantes para el paso actual
    const fieldsToValidate =
      activeStep === 0
        ? [
            "nombre",
            "marca",
            "modelo",
            "patente",
            "anioFabricacion",
            "version",
            "color",
            "productoDestacado",
            "productoActivo",
          ]
        : [
            "combustible",
            "puertas",
            "transmision",
            "motor",
            "kilometros",
            "tanque",
            "precio",
            "productoDestacado",
            "productoActivo",
          ];

    // Validar los campos relevantes para el paso actual
    const errors = {};
    for (const field of fieldsToValidate) {
      if (formik.values[field] === undefined || formik.values[field] === "") {
        errors[field] = formik.errors[field];
        formik.setFieldTouched(field, true); // Establecer el campo como "touched"
      }
    }

    // Verificar si no hay errores de validación
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("marca", values.marca);
      formData.append("modelo", values.modelo);
      formData.append("patente", values.patente);
      formData.append("anioFabricacion", values.anioFabricacion);
      formData.append("version", values.version);
      formData.append("color", values.color);
      formData.append("combustible", values.combustible);
      formData.append("puertas", values.puertas);
      formData.append("transmision", values.transmision);
      formData.append("motor", values.motor);
      formData.append("kilometros", values.kilometros);
      formData.append("tanque", values.tanque);
      formData.append("precio", values.precio);
      formData.append("abs", checkboxValues.abs); // Incluir el valor de abs
      formData.append("gnc", checkboxValues.gnc); // Incluir el valor de gnc
      formData.append("nombre", values.nombre);
      formData.append(
        "productoDestacado",
        values.productoDestacado ? "true" : "false"
      );
      formData.append(
        "productoActivo",
        values.productoActivo ? "true" : "false"
      );
      files.forEach((image, index) => {
        formData.append(`image`, image);
      });
      if (data?._id) {
        // Si existe el ID del producto, es una actualización
        const response = await ProductService.updateProduct(data._id, formData);
        MySwal.fire({
          title: <p>Producto actualizado exitosamente</p>,
          icon: "success",
          confirmButtonColor: '#ff9800'
        });
        updateTableData(response);
      } else {
        // Si no existe el ID del producto, es una creación
        const response = await ProductService.createProduct(formData);
        setSnackbarMessage(`El Producto fue creado con éxito.`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        updateTableData(response);
      }
      // Reiniciar el formulario
      formik.resetForm();
      setFiles([]);
      setActiveStep(0);
    } catch (error) {
      console.error("Error al guardar el producto:", error);
    }
  };

  return (
    <Grid container>
      <Typography variant="h4" gutterBottom>
        {steps[activeStep]}
      </Typography>
      <form style={{ marginTop: "20px" }}>
        {activeStep === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nombre"
                  placeholder="Nombre de la Publicacion"
                  variant="standard"
                  id="nombre"
                  name="nombre"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Marca"
                  placeholder="Fiat"
                  InputLabelProps={{ shrink: true }}
                  id="marca"
                  name="marca"
                  variant="standard"
                  fullWidth
                  value={formik.values.marca}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.marca && Boolean(formik.errors.marca)}
                  helperText={formik.touched.marca && formik.errors.marca}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Modelo"
                  placeholder="Toro"
                  variant="standard"
                  id="modelo"
                  name="modelo"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formik.values.modelo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                  helperText={formik.touched.modelo && formik.errors.modelo}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Patente"
                  placeholder="AB 150 CD"
                  variant="standard"
                  id="patente"
                  name="patente"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formik.values.patente}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.patente && Boolean(formik.errors.patente)
                  }
                  helperText={formik.touched.patente && formik.errors.patente}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Año de Fabricación"
                  variant="standard"
                  placeholder="2024"
                  id="anioFabricacion"
                  InputLabelProps={{ shrink: true }}
                  name="anioFabricacion"
                  type="number"
                  fullWidth
                  value={formik.values.anioFabricacion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.anioFabricacion &&
                    Boolean(formik.errors.anioFabricacion)
                  }
                  helperText={
                    formik.touched.anioFabricacion &&
                    formik.errors.anioFabricacion
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Versión"
                  placeholder="1.8 Freedom"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  id="version"
                  name="version"
                  fullWidth
                  value={formik.values.version}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.version && Boolean(formik.errors.version)
                  }
                  helperText={formik.touched.version && formik.errors.version}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Color"
                  variant="standard"
                  placeholder="Blanco"
                  InputLabelProps={{ shrink: true }}
                  id="color"
                  name="color"
                  fullWidth
                  value={formik.values.color}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.color && Boolean(formik.errors.color)}
                  helperText={formik.touched.color && formik.errors.color}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!formik.values.productoDestacado}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      name="productoDestacado"
                    />
                  }
                  label="Producto destacado"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.productoActivo}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      name="productoActivo"
                    />
                  }
                  label="Producto activo"
                />
              </Grid>
            </Grid>
          </>
        )}

        {activeStep === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Combustible"
                  variant="standard"
                  id="combustible"
                  name="combustible"
                  fullWidth
                  select // Añadir esta prop para hacer que sea un select
                  SelectProps={{ native: true }} // Añadir esta prop para que sea un select nativo
                  value={formik.values.combustible}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.combustible &&
                    Boolean(formik.errors.combustible)
                  }
                  helperText={
                    formik.touched.combustible && formik.errors.combustible
                  }
                >
                  <option value="">Seleccione una opcion</option>
                  <option value="Nafta">Nafta</option>
                  <option value="Gasoil">Gasoil</option>
                  <option value="Electrico">Eléctrico</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Puertas"
                  variant="standard"
                  placeholder="4"
                  id="puertas"
                  name="puertas"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  fullWidth
                  value={formik.values.puertas}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.puertas && Boolean(formik.errors.puertas)
                  }
                  helperText={formik.touched.puertas && formik.errors.puertas}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Transmisión"
                  variant="standard"
                  id="transmision"
                  name="transmision"
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: true }}
                  value={formik.values.transmision}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.transmision &&
                    Boolean(formik.errors.transmision)
                  }
                  helperText={
                    formik.touched.transmision && formik.errors.transmision
                  }
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Manual">Manual</option>
                  <option value="Automática">Automática</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Motor"
                  variant="standard"
                  placeholder="1.8"
                  id="motor"
                  name="motor"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  fullWidth
                  value={formik.values.motor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.motor && Boolean(formik.errors.motor)}
                  helperText={formik.touched.motor && formik.errors.motor}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Kilómetros"
                  placeholder="1000"
                  variant="standard"
                  id="kilometros"
                  InputLabelProps={{ shrink: true }}
                  name="kilometros"
                  type="number"
                  fullWidth
                  value={formik.values.kilometros}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.kilometros &&
                    Boolean(formik.errors.kilometros)
                  }
                  helperText={
                    formik.touched.kilometros && formik.errors.kilometros
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Capacidad del tanque(litros)"
                  variant="standard"
                  placeholder="60"
                  id="tanque"
                  InputLabelProps={{ shrink: true }}
                  name="tanque"
                  type="number"
                  fullWidth
                  value={formik.values.tanque}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.tanque && Boolean(formik.errors.tanque)}
                  helperText={formik.touched.tanque && formik.errors.tanque}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Precio"
                  variant="standard"
                  placeholder="28500000"
                  id="precio"
                  name="precio"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  fullWidth
                  value={formik.values.precio}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.precio && Boolean(formik.errors.precio)}
                  helperText={formik.touched.precio && formik.errors.precio}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues.abs}
                      onChange={handleCheckboxChange}
                      onBlur={formik.handleBlur}
                      name="abs"
                    />
                  }
                  label="ABS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues.gnc}
                      onChange={handleCheckboxChange}
                      onBlur={formik.handleBlur}
                      name="gnc"
                    />
                  }
                  label="GNC"
                />
              </Grid>
            </Grid>
          </>
        )}

        {activeStep === 2 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FileUploader
                  handleChange={handleChange}
                  label="Arrastrar archivos aquí"
                  multiple={true}
                  name="file"
                  types={fileTypes}
                />
              </Grid>
              <Grid item container xs={12}>
                {files.length > 0 && (
                  <ImagePreview files={files} handleDelete={handleDelete} />
                )}
              </Grid>
              <Grid item xs={12}>
                {data && data.imagenes && data.imagenes.length > 0 && (
                  <Typography variant="h6" gutterBottom>
                    Imágenes Previamente Cargadas
                  </Typography>
                )}
                {data && data.imagenes && data.imagenes.length > 0 && (
                  <ImagePreviews images={data.imagenes} />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </form>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: "20px" }}
      >
        <Grid item>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
          >
            Atrás
          </Button>
        </Grid>
        <Grid item>
          {activeStep < steps.length - 1 && (
            <Button type="button" onClick={handleNext} variant="contained">
              Siguiente
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <CustomButton
              text={"Guardar"}
              icon={<Icon icon="mingcute:save-2-line" />}
              onClick={() => handleSubmit(formik.values)}
            />
          )}
        </Grid>
      </Grid>
      {/* Snackbar para mostrar mensajes */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};

export default ProductForm;
