import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";
import React from "react";

const NoResultsMessage = () => {
  return (
    <Grid
      container
      style={{ textAlign: "start", marginTop: 20, display: "flex" }}
    >
      <Grid item xs={3} sx={{justifyContent: 'end', display: 'flex'}}>
        <Icon
          icon="oui:face-sad"
          style={{ width: 60, height: 60, marginRight: "8px" }}
        />
      </Grid>
      <Grid item xs={9}>
        <Typography fontSize={20}>Ups!!!</Typography>
        <Typography fontSize={14}>
          No hay resultados con la combinacion de filtros seleccionados.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoResultsMessage;
