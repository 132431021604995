import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
//import Layout from "../components/Layout/index.jsx";
import privateRoutes from "./privateRoutes.js";
import ResetPassword from "../pages/ResetPassword";

// Función para verificar si el usuario está autenticado
function isAuthenticated() {
  const token = localStorage.getItem("token");
  return !!token; // Devuelve true si hay un token, false en caso contrario
}

const PageLayout = ({ redirectPath = "/login" }) => {
  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
    </>
  );
};

export default function AppRoute() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/" element={<Home />} />
      <Route element={<PageLayout />}>
        {privateRoutes.map((route, idx) =>
          route.children ? (
            <Route key={idx} path={route.path}>
              <route.component />
              {route.children.map((child, idx) => (
                <Route
                  key={idx}
                  path={child.path}
                  element={<child.component />}
                />
              ))}
            </Route>
          ) : (
            <Route key={idx} path={route.path} element={<route.component />} />
          )
        )}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
