import { Dialog, DialogTitle, Grid, Typography, Box } from "@mui/material";
import UserTable from "../../../components/UserTable"; // Suponiendo que tienes un componente UserTable
import CustomButton from "../../../components/CustomButton";
import UserForm from "../UserForm"; // Suponiendo que tienes un componente UserForm
import { Icon } from "@iconify/react";
import { useState } from "react";

export default function UserList() {
  const [open, setOpen] = useState(false);

  const handleListItemClick = (value) => {
    setOpen(value);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ padding: "20px", borderRadius: "10px" }}
    >
      <Box
        sx={{
          color: "#007aff",
          textAlign: "center",
          fontWeight: "600",
          padding: "10px",
          width: "100%",
          borderRadius: "10px",
          marginBottom: "20px",
        }}
      >
        <Typography fontWeight={"600"} variant="h5" color='primary'>
          Listado de Usuarios
        </Typography>
      </Box>
      <Grid item xs={12} sm={8} alignSelf="flex-end" sx={{marginRight: "50px"}}>
        <CustomButton
          text={"Crear usuario"}
          icon={<Icon icon="mdi:add" />}
          onClick={() => handleListItemClick(true)}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <UserTable />
        <Dialog onClose={() => handleListItemClick(false)} open={open}>
          <DialogTitle>Crear Usuario</DialogTitle>
          <UserForm onCloseForm={() => handleListItemClick(false)} />
        </Dialog>
      </Grid>
    </Grid>
  );
}
