import React, { useState, useEffect } from "react";
import { Typography, Paper, Grid, Box, Divider } from "@mui/material";
import ProductService from "../../services/products.service";
import Loader from "../Loading";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import Footer from "../Footer";

const CarDetailsPage = () => {
  const { id } = useParams();
  const [carDetails, setCarDetails] = useState(null);

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const data = await ProductService.getProductById(id);
        setCarDetails(data);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    fetchCarDetails();

    // Cleanup function if needed
    return () => {
      // Perform any cleanup if required
    };
  }, [id]);

  const detailItems = [
    {
      icon: "bi:calendar",
      label: "Año",
      value: carDetails?.anioFabricacion,
    },
    {
      icon: "bx:bxs-car",
      label: "Kilometros",
      value: `${carDetails?.kilometros} km`,
    },
    { icon: "mdi:engine", label: "Motor", value: carDetails?.motor },
    // { icon: "mdi:fuel", label: "Combustible", value: carDetails?.combustible },
    {
      icon: "mingcute:car-door-line",
      label: "Puertas",
      value: carDetails?.puertas,
    },
    { icon: "bi:palette", label: "Color", value: carDetails?.color },
    {
      icon: "mdi:gas-cylinder",
      label: "GNC",
      value: carDetails?.gnc ? "Sí" : "No",
    },
    {
      icon: "solar:transmission-bold",
      label: "Transmisión",
      value: carDetails?.transmision,
    },
    {
      icon: "mdi:car-brake-abs",
      label: "ABS",
      value: carDetails?.abs ? "Sí" : "No",
    },
    // {
    //   icon: "iconoir:gas-tank",
    //   label: "Tanque",
    //   value: `${carDetails?.tanque} lts`,
    // },
  ];

  return (
    <Grid
      container
      id="homeContainer"
      sx={{
        background: "#010101",
        height: '100vh',
        background: "linear-gradient(45deg, #010101, #606060);",
      }}
    >
      <Grid
        container
        id="heroContainer"
        sx={{ paddingTop: "15px", marginBottom: "40px", height: "80vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
        item xs={10}
          elevation={3}
          style={{
            padding: "20px",
            borderRadius: "8px",
            background: "rgba(72, 75, 92, 0.15)",
            WebkitBackdropFilter: "blur(7px)",
            backdropFilter: "blur(7px)",
            border: "1px solid rgba(72, 75, 92, 0.075)",
            color: "whitesmoke",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} container justifyContent={'center'}>
              <img
                src={carDetails?.imagenes}
                alt={`${carDetails?.marca} ${carDetails?.modelo}`}
                style={{
                  maxWidth: "100%",
                  height: "100%",
                  borderRadius: "8px",
                }}
              />
            </Grid>
            <Grid container item xs={12} md={9}>
              <Grid

                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography variant="h5" gutterBottom>
                  {`${carDetails?.marca} ${carDetails?.modelo} ${carDetails?.version}`}
                  {carDetails?.productoDestacado && (
                    <Icon
                      icon="bxs:offer"
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#FF9800",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </Typography>
                <Divider />
                <Grid container mt={2}>
                  {detailItems.map((detail, index) => (
                    <Grid key={index} item xs={6}  md={4} lg={3}>
                      <DetailWithIcon
                        icon={detail.icon}
                        label={detail.label}
                        value={detail.value}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

const DetailWithIcon = ({ icon, label, value }) => (
  <Grid container alignItems="center" m={1}>
    <Icon icon={icon} style={{ marginRight: "10px" }} width="24" height="24" />
    <Grid item>
    <Typography >{label}:</Typography>
    <Typography >
      <strong>{value}</strong>
    </Typography>
    </Grid>
  
  </Grid>
);

export default CarDetailsPage;
